import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useOktaProfile } from '@optools/shared/ui/hooks/useOktaProfile';
import { Button } from '@optools/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@optools/ui/popover';
import { Tooltip, TooltipContent, TooltipTrigger } from '@optools/ui/tooltip';
import { cn } from '@optools/ui/utils';
import { CircleUserRound, LogIn, LogOut } from 'lucide-react';

interface AccountMenuProps {
  open: boolean;
}

export function AccountMenu({ open }: AccountMenuProps) {
  const { authState, oktaAuth } = useOktaAuth();
  const isAuthenticated = !!authState?.isAuthenticated;
  const { profile } = useOktaProfile();

  const handleAuthClick = () => {
    if (isAuthenticated) {
      oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` });
      return;
    }

    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
  };

  return (
    <Tooltip {...(open && { open: false })}>
      <Popover>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              aria-label="Account"
              className={cn(
                'ease justify-start overflow-hidden rounded-lg px-2.5 transition-all duration-300',
                open ? 'w-60' : 'w-10',
              )}
              variant="ghost">
              <CircleUserRound className="size-5 shrink-0" />

              <span
                className={cn(
                  'ml-2 truncate transition-opacity duration-300',
                  open ? 'opacity-100 delay-100' : 'opacity-0',
                )}>
                {profile?.fullName || 'Account'}
              </span>
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>

        <PopoverContent
          align="end"
          alignOffset={2}
          className="w-max p-0"
          side="right"
          sideOffset={8}>
          {isAuthenticated && (
            <div className="flex flex-col gap-1 border-b p-3">
              <span className="pb-0 text-xl font-bold">{profile.fullName}</span>
              <span className="text-xs font-light">{profile.email}</span>
            </div>
          )}

          <div className="p-1">
            <Button
              className="flex w-full items-center justify-start gap-1"
              size="sm"
              variant="ghost"
              onClick={handleAuthClick}>
              {isAuthenticated ? <LogOut className="size-4" /> : <LogIn className="size-4" />}
              <span>{isAuthenticated ? 'Logout' : 'Login'}</span>
            </Button>
          </div>
        </PopoverContent>
      </Popover>

      <TooltipContent side="right" sideOffset={5}>
        Account
      </TooltipContent>
    </Tooltip>
  );
}
