import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { selectOktaConfig } from '@optools/shared/store/configApiSlice';
import { useAppDispatch, useAppSelector } from '@optools/shared/store/hooks';
import { ReactNode, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface AuthProps {
  children: ReactNode;
}

export default function Auth({ children }: AuthProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const oktaConfig = useAppSelector(selectOktaConfig);
  const oktaAuth = useMemo(() => new OktaAuth(oktaConfig), [oktaConfig]);

  const restoreOriginalUri = (_: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    oktaAuth.start();

    // Workaround for Okta Auth JS not renewing tokens automatically on localhost
    if (process.env.NODE_ENV === 'development') {
      oktaAuth.tokenManager.on('expired', () => oktaAuth.authStateManager.updateAuthState());
      oktaAuth.tokenManager.on('renewed', () => oktaAuth.authStateManager.updateAuthState());
    }

    return () => {
      if (process.env.NODE_ENV === 'development') {
        oktaAuth.tokenManager.off('expired');
        oktaAuth.tokenManager.off('renewed');
      }
      oktaAuth.stop();
    };
  }, [dispatch, oktaAuth]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
}
