import { LucideProps } from 'lucide-react';
import { Link, LinkProps } from 'react-router-dom';

import { Card } from './card';

export interface ToolTileProps {
  icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
  >;
  title: string;
  to: LinkProps['to'];
}

function ToolTile({ icon: Icon, title, to }: ToolTileProps) {
  return (
    <Link to={to}>
      <Card className="size-[164px] content-center items-center p-6 transition-colors hover:bg-muted">
        <div className="flex flex-col items-center justify-center">
          <Icon className="size-16 p-3" />
          <p className="text-center text-sm">{title}</p>
        </div>
      </Card>
    </Link>
  );
}

export default ToolTile;
