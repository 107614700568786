import { useOktaAuth } from '@okta/okta-react';
import {
  OktaProfile,
  selectInitials,
  useUserProfileQuery,
} from '@optools/shared/store/oktaApiSlice';

export const DEFAULT_PROFILE: OktaProfile = {
  email: '',
  firstName: '',
  fullName: '',
  groups: [],
  id: '',
  lastName: '',
};

export const useOktaProfile = () => {
  const { authState, oktaAuth } = useOktaAuth();

  return useUserProfileQuery(oktaAuth.token.getUserInfo, {
    selectFromResult: ({ data, ...rest }) => ({
      initials: selectInitials(data),
      profile: data ?? DEFAULT_PROFILE,
      ...rest,
    }),
    skip: !authState || !authState?.isAuthenticated,
  });
};
