import { cn } from '@optools/ui/utils';
import { LoaderCircle } from 'lucide-react';

export interface PageLoaderProps {
  className?: string;
  withDelay?: boolean;
}

export function PageLoader({ className, withDelay = false }: PageLoaderProps) {
  return (
    <div
      className={cn(
        'absolute left-0 top-0 flex h-full w-full flex-1 items-center justify-center opacity-0',
        withDelay ? 'animate-fade-in delay-1000' : 'opacity-1',
        className,
      )}>
      <LoaderCircle className="ease size-10 animate-spin" role="progressbar" />
    </div>
  );
}
