import { appEnvironment } from '@optools/services/config/appConfig';
import { APP_ENVIRONMENTS } from '@optools/services/config/types';
import {
  AUTH_GROUP_MEDIA_SERVICES,
  AUTH_GROUPS_AUDIT,
  AUTH_GROUPS_BTC_TOOLS,
  AUTH_GROUPS_EXPERIENCE_OVERRIDE,
  AUTH_GROUPS_GAME_FEATURES,
  AUTH_GROUPS_IP_OVERRIDE,
  AUTH_GROUPS_LOGGER,
  AUTH_GROUPS_MEDIA_VALIDATION,
  AUTH_GROUPS_MILESTONES,
  AUTH_GROUPS_PARTNER,
  checkGroupAuth,
  OKTA_GROUPS,
} from '@optools/utils/auth';
import { useLocation } from 'react-router-dom';

import { useOktaProfile } from './useOktaProfile';

const RED5__AUTH_GROUPS = [
  OKTA_GROUPS.STREAMING_ADMIN,
  OKTA_GROUPS.STREAMING_DEVELOPER,
  OKTA_GROUPS.STREAMING_MEDIA_OPERATORS,
  OKTA_GROUPS.STREAMING_INFOSEC,
  OKTA_GROUPS.STREAMING_PRODUCT_MANAGER,
  OKTA_GROUPS.STREAMING_STREAMING_LOGGER,
];

export const useAccessCheck = () => {
  const { profile } = useOktaProfile();
  const location = useLocation();
  const showAudit = checkGroupAuth(AUTH_GROUPS_AUDIT, profile.groups);
  const showBtcTools = checkGroupAuth(AUTH_GROUPS_BTC_TOOLS, profile.groups);
  const showExpOverride = checkGroupAuth(AUTH_GROUPS_EXPERIENCE_OVERRIDE, profile.groups);
  const showGameFeatures =
    import.meta.env.DEV && // TODO: Remove when feature is ready in OGW
    checkGroupAuth(AUTH_GROUPS_GAME_FEATURES, profile.groups);
  const showIpOverride = checkGroupAuth(AUTH_GROUPS_IP_OVERRIDE, profile.groups);
  const showMediaServices =
    appEnvironment !== APP_ENVIRONMENTS.PROD &&
    checkGroupAuth(AUTH_GROUP_MEDIA_SERVICES, profile.groups);
  const showMediaValidation = checkGroupAuth(AUTH_GROUPS_MEDIA_VALIDATION, profile.groups);
  const showMilestones = checkGroupAuth(AUTH_GROUPS_MILESTONES, profile.groups);
  const showLogger = checkGroupAuth(AUTH_GROUPS_LOGGER, profile.groups);
  const showPartnerStreamDelivery = checkGroupAuth(AUTH_GROUPS_PARTNER, profile.groups);
  const showRed5 =
    location.search.indexOf('red5=true') > -1 &&
    appEnvironment !== APP_ENVIRONMENTS.PROD &&
    checkGroupAuth(RED5__AUTH_GROUPS, profile.groups);

  return {
    showAudit,
    showBtcTools,
    showExpOverride,
    showGameFeatures,
    showIpOverride,
    showLogger,
    showMediaServices,
    showMediaValidation,
    showMilestones,
    showPartnerStreamDelivery,
    showRed5,
  };
};
